<template>
    <div>
        <v-card flat color="transparent">
            <v-card-title class="text-h6 pb-0"
                ><span v-if="model.heading">{{ model.heading }}:</span
                ><span v-else>&nbsp;</span></v-card-title
            >
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col
                            cols="12"
                            :md="!isPenaltyFee && !isScenario ? 6 : (isPenaltyFee || isScenario) ? 4 : 12"
                        >
                            <v-autocomplete
                                :items="categoryItems"
                                v-model="model.category"
                                :label="$t('admin.community.category.table.label')"
                                :hint="$t('admin.community.category.table.hint')"
                                :error-messages="errors.collect('category')"
                                data-vv-name="category"
                                v-validate="'required'"
                                required
                                dense
                                ref="dialogFirstField"
                                :key="model.id + 'category'"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col
                            v-if="isPenaltyFee"
                            cols="12"
                            :md="isPenaltyFee ? 4 : 12"
                        >
                            <v-autocomplete
                                :items="penaltyFeeCategoryItems"
                                v-model="model.penaltyFeeCategory"
                                :label="
                                    $t(
                                        'admin.community.penaltyFeeCategory.table.label'
                                    )
                                "
                                :hint="
                                    $t(
                                        'admin.community.penaltyFeeCategory.table.hint'
                                    )
                                "
                                :error-messages="
                                    errors.collect('penaltyFeeCategory')
                                "
                                data-vv-name="penaltyFeeCategory"
                                v-validate="'required'"
                                required
                                dense
                                :key="model.id + 'penaltyFeeCategory'"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col v-if="isScenario" cols="12" :md="isScenario ? 4 : 12">
                            <v-autocomplete
                                :items="departmentCategoryItems"
                                v-model="model.departmentCategory"
                                :label="
                                    $t(
                                        'admin.community.departmentCategory.table.label'
                                    )
                                "
                                :hint="
                                    $t(
                                        'admin.community.departmentCategory.table.hint'
                                    )
                                "
                                :error-messages="
                                    errors.collect('departmentCategory')
                                "
                                data-vv-name="departmentCategory"
                                v-validate="'required'"
                                required
                                item-value="id"
                                item-text="title"
                                dense
                                :key="model.id + 'departmentCategory'"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                            :md="(!isPenaltyFee && !isScenario) ? 6 : (isPenaltyFee || isScenario) ? 4 : 12"
                        >
                            <v-autocomplete
                                :items="statusItems"
                                v-model="model.status"
                                :label="$t('admin.community.status.table.label')"
                                :hint="$t('admin.community.status.table.hint')"
                                :error-messages="errors.collect('status')"
                                data-vv-name="status"
                                v-validate="'required'"
                                required
                                dense
                                item-value="value"
                                item-text="text"
                                :key="model.id + 'status'"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                v-model="dateDialog"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-on="on"
                                        v-model="dateModel"
                                        :label="
                                            $t('admin.community.date.table.label')
                                        "
                                        :hint="
                                            $t('admin.community.date.table.hint')
                                        "
                                        prepend-icon="event"
                                        readonly
                                        style="padding-bottom:25px; padding-top:20px;"
                                        :key="model.id + 'date'"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    first-day-of-week="1"
                                    v-model="dateModel"
                                    @input="dateDialog = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                                :items="countries"
                                v-model="model.countries"
                                :label="$t('admin.community.countries.table.label')"
                                :hint="$t('admin.community.countries.table.hint')"
                                item-value="countryCode"
                                item-text="countryName"
                                multiple
                                chips
                                small-chips
                                deletable-chips
                                dense
                                :key="model.id + 'countries'"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="model.heading"
                                :label="$t('admin.community.heading.table.label')"
                                :hint="$t('admin.community.heading.table.hint')"
                                :error-messages="errors.collect('heading')"
                                data-vv-name="heading"
                                v-validate="'required'"
                                required
                                dense
                                :key="model.id + 'heading'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="model.subheading"
                                :label="
                                    $t('admin.community.subheading.table.label')
                                "
                                :hint="$t('admin.community.subheading.table.hint')"
                                dense
                                :key="model.id + 'subheading'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="model.nameOfAuthority"
                                :label="
                                    $t(
                                        'admin.community.nameOfAuthority.table.label'
                                    )
                                "
                                :hint="
                                    $t('admin.community.nameOfAuthority.table.hint')
                                "
                                dense
                                :key="model.id + 'nameOfAuthority'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="model.summary"
                                :label="$t('admin.community.summary.table.label')"
                                :hint="$t('admin.community.summary.table.hint')"
                                dense
                                :key="model.id + 'summary'"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <LeaTipTapEditor
                                v-model="model.content"
                                :label="$t('admin.community.content.table.label')"
                                :hint="$t('admin.community.content.table.hint')"
                                :error-messages="errors.collect('content')"
                                data-vv-name="content"
                                v-validate="'required'"
                                required
                                :key="model.id + 'content'"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="model.sourceURL"
                                :label="$t('admin.community.sourceURL.table.label')"
                                :hint="$t('admin.community.sourceURL.table.hint')"
                                dense
                                :key="model.id + 'sourceURL'"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <Combobox
                                :items="tagItems"
                                v-model="model.tags"
                                items-dispatcher="community/unshiftTagItems"
                                :label="$t('admin.community.tags.table.label')"
                                :hint="$t('admin.community.tags.table.hint')"
                                validationName="tags"
                                :key="model.id + 'tags'"
                            />
                        </v-col>
                        <v-col cols="12">
                            <Combobox
                                :items="categoryTagItems"
                                v-model="model.categoryTags"
                                items-dispatcher="community/unshiftCategoryTagItems"
                                :label="
                                    $t('admin.community.categoryTags.table.label')
                                "
                                :hint="
                                    $t('admin.community.categoryTags.table.hint')
                                "
                                validationName="categoryTags"
                                :key="model.id + 'categoryTags'"
                            />
                        </v-col>
                        <v-col cols="12">
                            <label
                                class="text-subtitle-1"
                                v-text="
                                    $t('admin.community.attachments.table.label')
                                "
                            />
                            <v-subheader>
                                <v-spacer
                                    v-if="
                                        model.contentAttachments &&
                                            model.contentAttachments.length
                                    "
                                ></v-spacer>
                                <v-btn
                                    color="success"
                                    class="my-3"
                                    @click="addFile()"
                                >
                                    <v-icon>add</v-icon>
                                    <span>Neuen Dateiupload</span>
                                </v-btn>
                            </v-subheader>
                            <p
                                v-if="
                                    !model.contentAttachments ||
                                        !model.contentAttachments.length
                                "
                                v-text="
                                    $t('admin.community.attachments.table.none')
                                "
                            />
                            <v-list v-else dense>
                                <v-list-item
                                    v-for="(attachment,
                                    index) in model.contentAttachments"
                                    :key="attachment.id"
                                    @click="editFile(attachment, index)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                            attachment.title
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn
                                            icon
                                            @click.stop.prevent="
                                                editFile(attachment, index)
                                            "
                                        >
                                            <v-icon color="primary"
                                                >edit</v-icon
                                            >
                                        </v-btn>
                                    </v-list-item-action>
                                    <v-list-item-action>
                                        <v-btn
                                            icon
                                            @click.stop.prevent="
                                                removeFile(index)
                                            "
                                        >
                                            <v-icon color="error">clear</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click.native="$emit('closeEditMode')">{{
                    $t('cancel')
                }}</v-btn>
                <v-btn
                    color="primary"
                    text
                    @click.native="$emit('saveEditor')"
                    :disabled="$wait.is('save item')"
                    :loading="$wait.is('save item')"
                    >{{ $t('apply') }}</v-btn
                >
            </v-card-actions>
        </v-card>
        <v-dialog v-model="fileDialog" persistent max-width="50%">
            <v-card>
                <v-card-title class="text-h6 pb-0"
                    >Datei {{ editedFileItem.title }}:</v-card-title
                >
                <v-card-text>
                    <v-text-field
                        v-model="editedFileItem.title"
                        :label="$t('admin.community.attachmentTitle.table.label')"
                        :hint="$t('admin.community.attachmentTitle.table.hint')"
                        dense
                        ref="fileDialogFirstField"
                    ></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-text-field
                        v-model="editedFileItem.description"
                        :label="
                            $t('admin.community.attachmentDescription.table.label')
                        "
                        :hint="
                            $t('admin.community.attachmentDescription.table.hint')
                        "
                        dense
                    ></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-autocomplete
                        :items="languages"
                        v-model="editedFileItem.language"
                        :label="
                            $t('admin.community.attachmentLanguage.table.label')
                        "
                        :hint="$t('admin.community.attachmentLanguage.table.hint')"
                        dense
                    >
                    </v-autocomplete>
                </v-card-text>
                <v-card-text>
                    <v-list v-if="editedFileItem.fileUrl">
                        <v-list-item @click="downloadFileLink(editedFileItem)">
                            <v-list-item-action>
                                <v-icon color="primary">cloud_download</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="editedFileItem.title"
                                ></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-tooltip left color="primary">
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            color="error"
                                            @click.stop="
                                                removeUploadedFile(
                                                    editedFileItem
                                                )
                                            "
                                            >mdi-close</v-icon
                                        >
                                    </template>
                                    <span>{{ $t('delete') }}</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <template v-else>
                        <vue-dropzone
                            ref="myVueDropzone"
                            :id="'dropzone'"
                            :options="file.dropzoneOptions"
                            @vdropzone-sending="sendFile"
                            @vdropzone-success="addUpload"
                            :class="{
                                'theme--light': !this.$vuetify.dark,
                                'theme--dark': this.$vuetify.dark,
                            }"
                        ></vue-dropzone>
                        <div
                            class="v-messages v-messages--top-border pl-2"
                            :class="{
                                'theme--light': !this.$vuetify.dark,
                                'theme--dark': this.$vuetify.dark,
                            }"
                        >
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                    {{
                                        $t(
                                            'admin.community.attachmentFiles.table.hint'
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" text @click.native="fileDialog = false">{{
                        $t('cancel')
                    }}</v-btn>
                    <v-btn
                        color="success"
                        text
                        @click.native="applyFileDialog"
                        >{{ $t('apply') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import Combobox from './fields/Combobox.vue';
import LeaTipTapEditor from '@/components/Input/LeaTipTapEditor.vue';
import vueDropzone from 'vue2-dropzone';
import http from '@/utils/axios';
import FileSaver from 'file-saver';
import { parseISO, formatDate } from '@/utils/dateFns';

const DEFAULT_FILE_ITEM = {
    id: null,
    description: null,
    fileUrl: null,
    language: null,
    title: null,
};

export default {
    name: 'AdminRdarListEditorDialog',
    components: {
        vueDropzone,
        LeaTipTapEditor,
        Combobox,
    },
    i18n: {
        messages: {
            en: require('@/locales/Admin/en.json'),
            de: require('@/locales/Admin/de.json'),
        },
    },
    inject: {
        $validator: '$validator',
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        categoryItems: {
            type: Array,
            default: () => [],
        },
        penaltyFeeCategoryItems: {
            type: Array,
            default: () => [],
        },
        departmentCategoryItems: {
            type: Array,
            default: () => [],
        },
        statusItems: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => [],
        },
        tagItems: {
            type: Array,
            default: () => [],
        },
        categoryTagItems: {
            type: Array,
            default: () => [],
        },
    },
    data() {

        const uploadHeaders = {
            'Cache-Control': null,
            'X-Requested-With': null,
            Accept: `application/json, application/prs.lea+json;v=${process.env.VUE_APP_VERSION}`
        }

        if (process.env.VUE_APP_SSO !== 'true') {
            uploadHeaders.Authorization = `Bearer ${getToken()}`
        }

        return {
            languages: [
                {
                    value: 'de',
                    text: 'Deutsch',
                },
                {
                    value: 'en',
                    text: 'Englisch',
                },
                {
                    value: 'fr',
                    text: 'Französisch',
                },
                {
                    value: 'it',
                    text: 'Italienisch',
                },
                {
                    value: 'es',
                    text: 'Spanisch',
                },
                {
                    value: 'pl',
                    text: 'Polnisch',
                },
                {
                    value: 'nl',
                    text: 'Niederländisch',
                },
            ],
            dateDialog: false,
            fileDialog: false,
            editedFileIndex: -1,
            editedFileItem: {
                id: null,
                description: null,
                fileUrl: null,
                language: null,
                title: null,
            },
            file: {
                dropzoneOptions: {
                    headers: uploadHeaders,
                    dictDefaultMessage: this.$i18n.t(
                        'admin.community.attachmentFiles.table.label'
                    ),
                    addRemoveLinks: true,
                    url: `${process.env.VUE_APP_BASE_URL}/api/admin/contentAttachment/upload`,
                    thumbnailHeight: 120,
                    maxFilesize: 20,
                    maxFiles: 1,
                    createImageThumbnails: false,
                    acceptedFiles:
                        'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptm,.pptx',
                },
            },
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        dateModel: {
            get() {
                if (
                    !this.model ||
                    !this.model.date ||
                    this.model.date.substring(0, 1) === '-'
                ) {
                    return null;
                }
                return formatDate(parseISO(this.model.date), 'yyyy-MM-dd');
            },
            set(val) {
                this.model.date = val;
            },
        },
        isPenaltyFee() {
            return this.model.category && this.model.category === 'penalty_fee';
        },
        isScenario() {
            return this.model.category && this.model.category === 'scenario';
        },
    },
    methods: {
        addFile() {
            this.editFile(DEFAULT_FILE_ITEM, -1);
        },
        editFile(attachment, index) {
            this.editedFileIndex = index;
            this.editedFileItem = Object.assign({}, attachment);
            this.fileDialog = true;
            this.$nextTick(() => {
                this.$refs.dialogFirstField.blur();
                this.$refs.fileDialogFirstField.focus();
            });
        },
        addUpload(file, response) {
            this.editedFileItem.id = response.id;
            this.editedFileItem.fileUrl = response.fileUrl;
            this.editedFileItem.fileName = response.name;
            // this.$emit('modified')
            this.$refs.myVueDropzone.removeFile(file);
        },
        sendFile(file, xhr, formData) {
            formData.append('clientId', file.upload.uuid);
            formData.append('type', 'ENCLOSURE');
        },
        removeUploadedFile() {
            this.$set(this.editedFileItem, 'fileUrl', null);
        },
        downloadFileLink(file) {
            http.get(file.fileUrl, {
                responseType: 'blob',
            }).then(response => {
                FileSaver.saveAs(response.data, file.title + '.' + file.extension);
            });
        },
        async applyFileDialog() {
            this.fileDialog = false;
            this.saveFileModel();
            setTimeout(() => {
                this.editedFileItem = Object.assign({}, DEFAULT_FILE_ITEM);
                this.editedFileIndex = -1;
            }, 300);
        },
        saveFileModel() {
            if (this.editedFileIndex > -1) {
                this.$set(
                    this.model.contentAttachments,
                    this.editedFileIndex,
                    this.editedFileItem
                );
            } else {
                if (
                    !this.model.contentAttachments ||
                    !Array.isArray(this.model.contentAttachments)
                ) {
                    this.$set(this.model, 'contentAttachments', []);
                }
                this.model.contentAttachments.unshift(this.editedFileItem);
            }
        },
        removeFile(index) {
            if (confirm('Soll diese Datei wirklich entfernt werden?')) {
                this.model.contentAttachments.splice(index, 1);
            }
        }
    },
};
</script>
