var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.editMode)?_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.categoryItems,"label":_vm.$t('admin.community.filter.category.label'),"clearable":""},model:{value:(_vm.searchMaskQuery.category),callback:function ($$v) {_vm.$set(_vm.searchMaskQuery, "category", $$v)},expression:"searchMaskQuery.category"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.countries,"label":_vm.$t('admin.community.filter.countries.label'),"item-value":"countryCode","item-text":"countryName","multiple":"","clearable":""},model:{value:(_vm.searchMaskQuery.countries),callback:function ($$v) {_vm.$set(_vm.searchMaskQuery, "countries", $$v)},expression:"searchMaskQuery.countries"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.statusItems,"label":_vm.$t('admin.community.filter.status.label'),"clearable":""},model:{value:(_vm.searchMaskQuery.status),callback:function ($$v) {_vm.$set(_vm.searchMaskQuery, "status", $$v)},expression:"searchMaskQuery.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t('admin.community.filter.text.label'),"clearable":""},on:{"click:clear":function($event){return _vm.setFreeTextSearch(null)}},model:{value:(_vm.freeTextSearchModel),callback:function ($$v) {_vm.freeTextSearchModel=$$v},expression:"freeTextSearchModel"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5","sm":"5","md":"6","lg":"3","xl":"2","align-self":"center"}},[_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.mdAndUp,"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","fab":_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.addItem()}}},on),[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("add")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("Neuen Eintrag erstellen")]):_vm._e()],1)]}}],null,false,3581547661)},[_c('span',[_vm._v("Neuen Eintrag erstellen")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"pt-6",attrs:{"headers":_vm.headers,"items":_vm.communityItems,"options":_vm.options,"footer-props":_vm.footerProps,"server-items-length":_vm.totalItems,"item-key":"id","loading":_vm.fetchLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(headers){return _vm._l((headers.headers),function(header,i){return _c('th',{key:i,style:({
                            width: header.width,
                            'text-align': header.align,
                        })},[_vm._v(" "+_vm._s(_vm.$t('admin.community.' + header.langkey + '.label'))+" ")])})}},{key:"item",fn:function(props){return [_c('TableRow',{attrs:{"item":props.item,"index":props.index,"category":_vm.categoryItems.find(
                                function (x) { return x.value === props.item.category; }
                            ),"statusItems":_vm.statusItems,"penaltyFeeCategoryItems":_vm.penaltyFeeCategoryItems,"departmentCategoryItems":_vm.departmentCategoryItems},on:{"editItem":function($event){return _vm.editItem($event)},"removeItemConfirmation":function($event){return _vm.removeItemConfirmation($event)}}})]}},{key:"pageText",fn:function(ref){
                            var pageStart = ref.pageStart;
                            var pageStop = ref.pageStop;
                            var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('from'))+" "+_vm._s(itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"v-messages"},[_vm._v(" "+_vm._s(_vm.$t('admin.community.noEntries'))+" ")])]},proxy:true}],null,false,4248773073)})],1)],1):_vm._e(),(_vm.editMode)?_c('Editor',{key:_vm.editedItem.id,attrs:{"categoryItems":_vm.categoryItems,"penaltyFeeCategoryItems":_vm.penaltyFeeCategoryItems,"departmentCategoryItems":_vm.departmentCategoryItems,"statusItems":_vm.statusItems,"countries":_vm.countries,"tagItems":_vm.tagItems,"categoryTagItems":_vm.categoryTagItems},on:{"closeEditMode":function($event){return _vm.closeEditMode()},"saveEditor":function($event){return _vm.saveEditor()}},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}}):_vm._e(),_c('BaseConfirmationDialog',{attrs:{"title":_vm.$t('admin.community.deleteItemConfirmation.title'),"text":_vm.$t('admin.community.deleteItemConfirmation.text'),"confirmationText":_vm.$t('delete')},on:{"confirm":function($event){return _vm.removeItem()},"cancel":function($event){_vm.deleteRequestItem = null}},model:{value:(_vm.deletionDialog),callback:function ($$v) {_vm.deletionDialog=$$v},expression:"deletionDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }