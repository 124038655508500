<template>
    <v-card-text>
        <v-combobox
            :items="items"
            v-model="model"
            v-clear-input-on-change
            v-clear-input-on-blur
            :label="label"
            :hint="hint"
            :error-messages="errors.collect(validationName)"
            :data-vv-name="validationName"
            item-value="id"
            item-text="title"
            :delimiters="[',']"
            :multiple="multiple"
            chips
            small-chips
            deletable-chips
            persistent-hint
            dense
            spellcheck="true"
        >
            <template v-slot:no-data>
                <v-alert
                    type="success"
                    class="mx-0 my-0 text-body-2"
                    v-text="$t('createItemHintComma')"
                />
            </template>
        </v-combobox>
    </v-card-text>
</template>

<script>
import ModelMixin from '@/components/vvt/ModelMixin';
export default {
    name: 'AdminCommunityCombobox',
    mixins: [ModelMixin],
    inject: {
        $validator: '$validator',
    },
    props: {
        value: Array,
        items: Array,
        itemsDispatcher: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        validationName: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Admin/en.json'),
            de: require('@/locales/Admin/de.json'),
        },
    },
    computed: {
        model: {
            get() {
                return this.getValue(this.items, this.value);
            },
            set(val) {
                const data =
                    this.setValue(
                        this.items,
                        val,
                        'title',
                        null,
                        this.itemsDispatcher
                    ) || [];
                this.$emit('input', data);
            },
        },
    },
};
</script>
