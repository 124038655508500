<template>
    <div>
        <AdminCommunityListComp />
    </div>
</template>

<script>
import AdminCommunityListComp from '@/components/admin/community/AdminCommunityListComp.vue';

export default {
    name: 'AdminCommunityList',
    components: {
        AdminCommunityListComp,
    },
};
</script>
