<template>
    <v-container fluid>
        <v-row v-if="!editMode" justify="space-around">
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                    :items="categoryItems"
                    v-model="searchMaskQuery.category"
                    :label="$t('admin.community.filter.category.label')"
                    clearable
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-autocomplete
                    :items="countries"
                    v-model="searchMaskQuery.countries"
                    :label="$t('admin.community.filter.countries.label')"
                    item-value="countryCode"
                    item-text="countryName"
                    multiple
                    clearable
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                    :items="statusItems"
                    v-model="searchMaskQuery.status"
                    :label="$t('admin.community.filter.status.label')"
                    clearable
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                    :label="$t('admin.community.filter.text.label')"
                    v-model="freeTextSearchModel"
                    clearable
                    @click:clear="setFreeTextSearch(null)"
                ></v-text-field>
            </v-col>
            <v-col
                cols="5"
                sm="5"
                md="6"
                lg="3"
                xl="2"
                align-self="center"
                class="text-right"
            >
                <v-tooltip
                    :disabled="$vuetify.breakpoint.mdAndUp"
                    bottom
                    color="success"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            color="success"
                            :fab="$vuetify.breakpoint.smAndDown"
                            :small="$vuetify.breakpoint.smAndDown"
                            @click="addItem()"
                        >
                            <v-icon :left="$vuetify.breakpoint.mdAndUp"
                                >add</v-icon
                            >
                            <span v-if="$vuetify.breakpoint.mdAndUp"
                                >Neuen Eintrag erstellen</span
                            >
                        </v-btn>
                    </template>
                    <span>Neuen Eintrag erstellen</span>
                </v-tooltip>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="communityItems"
                    :options.sync="options"
                    :footer-props="footerProps"
                    :server-items-length="totalItems"
                    item-key="id"
                    class="pt-6"
                    :loading="fetchLoader"
                >
                    <template v-slot:headers="headers">
                        <th
                            v-for="(header, i) in headers.headers"
                            :key="i"
                            :style="{
                                width: header.width,
                                'text-align': header.align,
                            }"
                        >
                            {{ $t('admin.community.' + header.langkey + '.label') }}
                        </th>
                    </template>
                    <template v-slot:item="props">
                        <TableRow
                            :item="props.item"
                            :index="props.index"
                            :category="
                                categoryItems.find(
                                    x => x.value === props.item.category
                                )
                            "
                            :statusItems="statusItems"
                            :penaltyFeeCategoryItems="penaltyFeeCategoryItems"
                            :departmentCategoryItems="departmentCategoryItems"
                            @editItem="editItem($event)"
                            @removeItemConfirmation="
                                removeItemConfirmation($event)
                            "
                        />
                    </template>
                    <template
                        v-slot:pageText="{ pageStart, pageStop, itemsLength }"
                    >
                        {{ pageStart }} - {{ pageStop }} {{ $t('from') }}
                        {{ itemsLength }}
                    </template>
                    <template v-slot:no-data>
                        <div class="v-messages">
                            {{ $t('admin.community.noEntries') }}
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <Editor
            v-if="editMode"
            v-model="editedItem"
            :key="editedItem.id"
            :categoryItems="categoryItems"
            :penaltyFeeCategoryItems="penaltyFeeCategoryItems"
            :departmentCategoryItems="departmentCategoryItems"
            :statusItems="statusItems"
            :countries="countries"
            :tagItems="tagItems"
            :categoryTagItems="categoryTagItems"
            @closeEditMode="closeEditMode()"
            @saveEditor="saveEditor()"
        />
        <BaseConfirmationDialog
            v-model="deletionDialog"
            :title="$t('admin.community.deleteItemConfirmation.title')"
            :text="$t('admin.community.deleteItemConfirmation.text')"
            :confirmationText="$t('delete')"
            @confirm="removeItem()"
            @cancel="deleteRequestItem = null"
        />
    </v-container>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { debounce } from 'lodash';
import { isUUID } from '@/components/vvt/ModelMixin';
import { mapGetters, mapActions } from 'vuex';
import Editor from './Editor.vue';
import TableRow from './TableRow.vue';

const LIMIT = 10;
const DEFAULT_ITEM = {
    category: null,
    heading: null,
    subheading: null,
    date: null,
    countries: [],
    nameOfAuthority: null,
    departmentCategory: null,
    penaltyFeeCategory: null,
    summary: null,
    content: null,
    status: null,
    sourceURL: null,
    tags: [],
    categoryTags: [],
    contentAttachments: [],
    relevantItems: [],
};

export default {
    name: 'AdminCommunityListComp',
    $_veeValidate: {
        validator: 'new', // see https://github.com/baianat/vee-validate/issues/1547
    },
    components: {
        Editor,
        TableRow,
    },
    i18n: {
        messages: {
            en: require('@/locales/Admin/en.json'),
            de: require('@/locales/Admin/de.json'),
        },
    },
    data() {
        return {
            name: 'AdminCommunityListComp',
            editMode: !!this.$route.params.id,
            fetchLoader: false,
            componentLoader: false,
            editedIndex: -1,
            deletionDialog: false,
            deleteRequestItem: null,
            limit: LIMIT,
            options: { sortDesc: true, itemsPerPage: LIMIT },
            footerProps: {
                itemsPerPageOptions: [LIMIT],
                itemsPerPageText: this.$t('perPage'),
                pageText: '{0}-{1} ' + this.$t('from') + ' {2}'
            },
            totalItems: 0,
            communityItems: [],
            searchMaskQuery: {
                searchTerm: null,
                category: null,
                status: null,
                countries: null,
            },
            categoryItems: [
                {
                    value: 'authority_news',
                    text: 'Behördenmeldung',
                },
                {
                    value: 'template',
                    text: 'Template',
                },
                {
                    value: 'scenario',
                    text: 'Szenario',
                },
                {
                    value: 'legitimate_interest',
                    text: 'Berechtigtes Interesse',
                },
                {
                    value: 'penalty_fee',
                    text: 'Bußgeld',
                },
                {
                    value: 'lea_news',
                    text: 'LEA News',
                },
            ],
            penaltyFeeCategoryItems: [
                {
                    value: 'unknown',
                    text: 'Unbekannt',
                },
                {
                    value: 'dpa',
                    text: 'Unzureichende Datenverarbeitungsvereinbarung',
                },
                {
                    value: 'rights_of_data_subjects',
                    text:
                        'Unzureichende Erfüllung der Rechte der betroffenen Personen',
                },
                {
                    value: 'legal_basis',
                    text:
                        'Unzureichende Rechtsgrundlage für die Datenverarbeitung',
                },
                {
                    value: 'tom',
                    text:
                        'Unzureichende technische und organisatorische Maßnahmen zur Gewährleistung der Informationssicherheit',
                },
                {
                    value: 'processing_of_personal_data',
                    text:
                        'Verstöße gegen die Grundsätze der Verarbeitung personenbezogener Daten',
                },
            ],
            statusItems: [
                {
                    value: 'draft',
                    text: 'Entwurf',
                },
                {
                    value: 'preview',
                    text: 'Vorschau',
                },
                {
                    value: 'published',
                    text: 'Veröffentlicht',
                },
            ],
            editedItem: {
                category: null,
                heading: null,
                subheading: null,
                date: null,
                countries: [],
                nameOfAuthority: null,
                departmentCategory: null,
                penaltyFeeCategory: null,
                summary: null,
                content: null,
                status: null,
                sourceURL: null,
                tags: [],
                categoryTags: [],
                contentAttachments: [],
                relevantItems: [],
            },
            max100chars: v => v.length <= 100 || 'Input too long!',
            headers: [
                {
                    langkey: 'category.table',
                    value: 'category',
                    sortable: true,
                    align: 'left',
                    width: '10%',
                },
                {
                    langkey: 'heading.table',
                    value: 'heading',
                    sortable: false,
                    align: 'left',
                    width: '20%',
                },
                {
                    langkey: 'date.table',
                    value: 'date',
                    sortable: false,
                    align: 'left',
                    width: '20%',
                },
                {
                    langkey: 'actions.table',
                    value: 'actions',
                    width: '10%',
                    align: 'right',
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            countries: 'countries/getItems',
            tagItems: 'community/getTagItems',
            categoryTagItems: 'community/getCategoryTagItems',
            departmentCategoryItems: 'departmentCategories/getItems',
        }),
        freeTextSearchModel: {
            get() {
                return this.searchMaskQuery.text;
            },
            set: debounce(function(val) {
                if (val.length > 2) {
                    this.setFreeTextSearch(val);
                } else {
                    this.setFreeTextSearch(null);
                }
            }, 500),
        },
    },
    methods: {
        ...mapActions({
            fetchCountries: 'countries/fetch',
            fetchFilterItems: 'community/fetchFilterItems',
            fetchTagItems: 'community/fetchTagItems',
            fetchCategoryTagItems: 'community/fetchCategoryTagItems',
            fetchDepartmentCategories: 'departmentCategories/fetch',
            newStoreItem: 'community/addItem',
            editStoreItem: 'community/editItem',
            replaceTagUuidsWithId: 'community/replaceTagUuidsWithId',
            replaceCategoryTagUuidsWithId:
                'community/replaceCategoryTagUuidsWithId',
            replaceItemUuidWithId: 'community/replaceItemUuidWithId',
            deleteItem: 'community/deleteItem',
        }),
        setFreeTextSearch(val) {
            this.$set(this.searchMaskQuery, 'text', val);
        },
        async fetchData() {
            this.$wait.start('pageLoader');
            this.fetchLoader = true;

            await Promise.all([
                this.fetchCountries(),
                this.fetchTagItems(),
                this.fetchCategoryTagItems(),
                this.fetchDepartmentCategories(),
            ]);

            this.fetchLoader = false;
            this.dataFetched();
            this.$wait.end('pageLoader');
        },
        dataFetched() {},
        async fetchByPage(page) {
            this.$wait.start('loading community page');
            this.communityItems = [];
            this.fetchFilterItems({
                adminScope: true,
                page: page,
                limit: this.limit,
                text:
                    this.searchMaskQuery && this.searchMaskQuery.text
                        ? this.searchMaskQuery.text
                        : null,
                searchCategory:
                    this.searchMaskQuery && this.searchMaskQuery.category
                        ? this.searchMaskQuery.category
                        : null,
                countryFilter: [],
                status:
                    this.searchMaskQuery && this.searchMaskQuery.status
                        ? this.searchMaskQuery.status
                        : null,
                countries:
                    this.searchMaskQuery && this.searchMaskQuery.countries
                        ? this.searchMaskQuery.countries
                        : null,
                storeMutation: 'SET_ITEMS',
            }).then(data => {
                this.communityItems = data.result;
                this.totalItems = data.total;
                this.$wait.end('loading community page');
            });
        },
        addItem() {
            DEFAULT_ITEM.contentAttachments = [];
            this.editItem({
                index: 0,
                item: DEFAULT_ITEM,
            });
            this.$router.push({
                name: 'AdminCommunityList',
                params: { id: 'new' },
            });
        },
        editItem(props) {
            this.editedIndex = props.index;
            this.editedItem = Object.assign({}, props.item);
            this.$router.push({
                name: 'AdminCommunityList',
                params: { id: props.item.id },
            });
        },
        saveEditor() {
            this.errors.clear();
            this.$validator.validate().then(validationResult => {
                if (!validationResult) {
                    /*
                    console.log(
                        'validation error',
                        validationResult,
                        this.$validator.errors
                    );
                    */
                } else {
                    this.saveModel();
                }
            });
        },
        saveModel() {
            this.$wait.start('save item');
            const itemId = this.editedItem.id;
            const newTagItems = this.$store.getters['community/getTagUuidItems'];
            const newCategoryTagItems = this.$store.getters[
                'community/getCategoryTagUuidItems'
            ];
            const persistModel = {
                ...this.editedItem,
                newTagItems,
                newCategoryTagItems,
            };
            if (!itemId || isUUID(itemId)) {
                const newItemId = itemId || uuid();
                this.newStoreItem({
                    item: { ...persistModel, ...{ id: newItemId } },
                }).then(dispatchResult => {
                    this.replaceUuids(dispatchResult, newItemId);
                    this.$wait.end('save item');
                    this.$router.push({ name: 'AdminCommunityList' });
                });
            } else {
                this.editStoreItem({
                    id: itemId,
                    item: persistModel,
                }).then(dispatchResult => {
                    this.replaceUuids(dispatchResult, itemId);
                    this.$wait.end('save item');
                    this.$router.push({ name: 'AdminCommunityList' });
                });
            }
        },
        replaceUuids(dispatchResult, id) {
            const statusCode = dispatchResult.response
                ? dispatchResult.response.status
                : dispatchResult.status;
            if (dispatchResult && (statusCode === 200 || statusCode === 204)) {
                const dispatchResultData = dispatchResult.data;
                if (
                    dispatchResultData &&
                    !Number.isInteger(id) &&
                    dispatchResultData.id
                ) {
                    this.replaceItemUuidWithId({
                        uuid: id,
                        newId: dispatchResultData.id,
                    });
                }
                if (dispatchResultData && dispatchResultData.newTags) {
                    this.replaceTagUuidsWithId(dispatchResultData.newTags);
                }
                if (dispatchResultData && dispatchResultData.newCategoryTags) {
                    this.replaceCategoryTagUuidsWithId(
                        dispatchResultData.newCategoryTags
                    );
                }
            }
        },
        removeItemConfirmation(item) {
            this.deleteRequestItem = item;
            this.deletionDialog = true;
        },
        async removeItem() {
            if (this.deleteRequestItem) {
                this.$wait.start('delete item ' + this.deleteRequestItem.id);
                await this.deleteItem(this.deleteRequestItem);
                this.$wait.end('delete item ' + this.deleteRequestItem.id);
                this.deleteRequestItem = null;
            }
        },
        closeEditMode() {
            this.$router.push({ name: 'AdminCommunityList' });
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route(to, from) {
            if (to.params.id !== from.params.id) {
                this.editMode = !!to.params.id;
            }
        },
        options: {
            handler(options) {
                this.fetchByPage(options.page);
            },
            deep: true,
        },
        searchMaskQuery: {
            handler() {
                this.options.page = 1;
                this.fetchByPage(1);
            },
            deep: true,
        },
    },
};
</script>
